import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentCode = makeShortcode("ComponentCode");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {
      /* todo: fix this? */
    }
    <ComponentCode name="Link" component="link" variation="link" hasReactVersion="link--default" hasVueVersion="link--default" hasAngularVersion="?path=/story/link--basic" codepen="bJpeEp" mdxType="ComponentCode" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      